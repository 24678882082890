const html = `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <title>
    </title>
    <!--[if !mso]><!-- -->
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
        body{
            font-family: 'Poppins', sans-serif; 
        }
        #outlook a {
            padding: 0;
        }
        /*
        headColor: #557EF8
        bodyColor: #4F46E5
        */

        .ReadMsgBody {
            width: 100%;
        }

        .ExternalClass {
            width: 100%;
        }

        .ExternalClass * {
            line-height: 100%;
        }

        body {
            margin: 0;
            padding: 0;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }

        table,
        td {
            border-collapse: collapse;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }

        img {
            border: 0;
            height: auto;
            line-height: 100%;
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
        }

        p {
            display: block;
            margin: 13px 0;
        }
    </style>
    <style type="text/css">
        @media only screen and (max-width:480px) {
            @-ms-viewport {
                width: 320px;
            }
            @viewport {
                width: 320px;
            }
        }
    </style>
    <style type="text/css">
        @media only screen and (min-width:480px) {
            .mj-column-per-100 {
                width: 100% !important;
            }
        }
    </style>
    <style type="text/css">
    </style>
</head>
<body style="background-color:#f9f9f9;">
    <div style="background-color:#f9f9f9;">
        <div style="background:#f9f9f9;background-color:#f9f9f9;Margin:0px auto;max-width:600px;">

            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#f9f9f9;background-color:#f9f9f9;width:100%;">
                <tbody>
                    <tr>
                        <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;vertical-align:top;">
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div style="background:#fff;background-color:#fff;Margin:0px auto;max-width:600px;">

            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fff;background-color:#fff;width:100%;">
                <tbody>
                    <tr>
                        <td style="border:#dddddd solid 1px;direction:ltr;font-size:0px;padding:0;text-align:center;vertical-align:top;">
                            <div class="mj-column-per-100 outlook-group-fix" style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:bottom;width:100%;">

                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:bottom;" width="100%">

                                    <tr>
                                        <td align="center" style="font-size:0px;padding:40px 25px;word-break:break-word; background-color:#fff;">

                                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                                <tbody>
                                                    <tr>
                                                        <td style="width:125px;height: auto;">
                                                            <img height="auto" src="https://back.sooda.fr/kolos-logo.png" style="border:0;display:block;outline:none;text-decoration:none;width:100%;" width="100" />

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td align="center" style="font-size:0px;padding:15px 25px;word-break:break-word;background-color:#4F46E5;">

                                            <div style="font-size:18px;font-weight: 600;line-height:22px;text-align:center;color:#fff;">
                                                L'outil dédié aux pilotage de chantier
                                            </div>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                                            <div style="font-size:14px;line-height:22px;text-align:left;color:#525252;">
                                                <p>Bonjour {prenom},</p>

                                                <p style="font-size:14px;line-height:22px;text-align:left;color:#525252;">Nous vous confirmons la bonne réception de votre demande. <br>
                                                    Notre équipe commerciale reviendra vers vous sous 48h (ouvrés). <br> <br>
                                                    En attendant, que diriez-vous d'une démo de 20 minutes pour découvrir comment optimiser le suivi de chantier ? <br>
                                                    <div style='width:100%;display:flex; align-items:center;justify-content:center'> <a href="https://calendly.com/palata_sooda/45min"  target='_blank' style="color:white;background-color:#4F46E5;padding:12px 16px;border-radius:8px;font-size:14px;font-weight:700">Prendre rendez-vous</a></div> <br>
                                                    Au plaisir d'échanger avec vous les optimisations de la mission d'OPC, bien trop souvent négligée... <br><br>
                                                    À très bientôt !
                                                </p>
                                            </div>

                                        </td>
                                    </tr>
                                   
                                    <tr>
                                        <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                                            <div style="font-size:14px;line-height:20px;text-align:left;color:#525252;">
                                               KOLOS Team<br>
                                                <a href="https://app.kolos.fr"  target='_blank' style="color:#4F46E5">Kolos.fr</a>
                                            </div>

                                        </td>
                                    </tr>

                                </table>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div style="Margin:0px auto;max-width:600px;">

            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                <tbody>
                    <tr>

                            <div class="mj-column-per-100 outlook-group-fix" style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:bottom;width:100%;">

                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align:bottom;padding:0;">

                                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">

                                                    <tr>
                                                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">

                                                            <div style="font-size:12px;font-weight:300;line-height:1;text-align:center;color:#575757;">
                                                                SOODA SAS: 6 rue des deux communes, 91480 QUINCY SOUS SENART
                                                            </div>

                                                        </td>
                                                    </tr>

                                                </table>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>


    </div>

</body>

</html>`
export default html